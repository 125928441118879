







































































































































import { MessagingModule } from "@/store/modules";
import helpers from "@/utils/helpers";
import { WaitUntilRefreshed } from "@/utils/jwt";
import SocketHandler from "@/utils/socketHandler";
import { Component, Vue, Watch } from "vue-property-decorator";
import pagination from "@/components/UIComponents/Pagination.vue";
import { Chat } from "@/store/models/chat";
import InfiniteLoading from "vue-infinite-loading";

@Component({
  components: { pagination, InfiniteLoading },
})
export default class AdminChat extends Vue {
  $refs!: {
    chatMessages: InstanceType<typeof HTMLElement>;
    infiniteLoading: InstanceType<typeof InfiniteLoading>;
  };

  latestChatFilterName: string = "";
  userChatSocket: WebSocket | undefined = undefined;
  allChatSocket: WebSocket | undefined = undefined;
  latestPaginatedChatList: any = [];
  selectedUserId: string = "";
  selectedUserName: string = "";
  isHidden: boolean = true;
  isSendingInProgress: boolean = false;

  sendMessage: Chat = {} as Chat;

  chatFilter: {
    totalPage: number;
    userId: string;
    pageSize: number | null;
  } = {} as {
    totalPage: number;
    userId: string;
    pageSize: number | null;
  };

  messages: Chat[] = [];

  get latestChatList() {
    let latestChatList = MessagingModule.latestChatList;
    if (this.latestChatFilterName && this.latestChatFilterName != "") {
      latestChatList = latestChatList.filter((x) =>
        x.publicIdName.toLowerCase().includes(this.latestChatFilterName)
      );
    }
    return latestChatList;
  }

  get selectedUserPaginatedChat() {
    return MessagingModule.selectedChatMessages;
  }

  get selectedUserMessageList() {
    return MessagingModule.selectedChatMessagesList;
  }

  get updateScrollToBottom() {
    return MessagingModule.scrollToBottom;
  }

  @Watch("updateScrollToBottom")
  onUpdateScrollToBottom() {
    this.scrollToBottom();
  }

  momentDate(date: any) {
    return helpers.toMomentDate(date);
  }

  async setSelectedUser(chat: Chat) {
    this.isHidden = false;
    this.selectedUserId = chat.publicId;
    this.selectedUserName = chat.publicIdName;
    this.chatFilter.userId = chat.publicId;
    this.chatFilter.pageSize = 1;
    MessagingModule.setAdminSelectedUserUid(this.selectedUserId);
    await MessagingModule.getSelectedUsersChat(this.chatFilter);
    this.scrollToBottom();

    // Reset infinite loader after selecting new user's message
    if (this.$refs.infiniteLoading) {
      this.$refs.infiniteLoading.stateChanger.reset();
    }

    this.chatFilter.totalPage = this.selectedUserPaginatedChat.totalPages;

    MessagingModule.updateSeenMessage(this.selectedUserId);
    let token = helpers.getAccessToken();
    if (this.userChatSocket) {
      this.userChatSocket.close();
    }
    this.userChatSocket = SocketHandler.createConnection(
      `chat/${this.selectedUserId}/?token=${token}`
    );
  }

  infiniteHandler($state: any) {
    if (
      this.chatFilter.pageSize &&
      this.chatFilter.pageSize < this.chatFilter.totalPage
    ) {
      this.chatFilter.pageSize++;
    } else {
      $state.loaded();
      $state.complete();
      return;
    }
    MessagingModule.getSelectedUsersChat(this.chatFilter)
      .then(async () => {
        // Sleep to prevent infinite loading after api hit
        // Messages are loaded from selectedUserMessageList getter
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        $state.loaded();
      })
      .catch(() => {
        $state.complete();
      });
  }

  scrollToBottom() {
    this.$nextTick(() => {
      const chatMessages = document.getElementsByClassName(
        "chat__message__wrapper"
      );
      if (chatMessages.length > 0) {
        const lastChatMessage = chatMessages[chatMessages.length - 1];
        lastChatMessage.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  onSendMessage() {
    if (
      this.sendMessage &&
      this.sendMessage.message &&
      this.sendMessage.message != ""
    ) {
      const message = {
        message: this.sendMessage.message,
        adminId: helpers.getUserUId() as any,
        publicId: this.selectedUserId,
      };
      const socketMessage = {
        data: message,
        type: "message",
      };
      if (this.userChatSocket) {
        this.isSendingInProgress = true;
        this.userChatSocket.send(JSON.stringify(socketMessage));
        this.isSendingInProgress = false;
        this.sendMessage = {} as Chat;
      }
    }
  }

  connectToAllChat() {
    let token = helpers.getAccessToken();
    this.allChatSocket = SocketHandler.createConnection(
      `chat/0/?token=${token}`
    );
  }
  async created() {
    await WaitUntilRefreshed();
    this.connectToAllChat();
  }

  beforeDestroy() {
    if (this.userChatSocket) {
      this.userChatSocket.close();
    }
  }
}
